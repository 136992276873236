import React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  height: 80vh;
  flex-direction: column;
  align-items: center;
  background-color: #eaedf3;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Não encontrado" />
    <NotFoundContainer>
      <h1>Não encontrado!</h1>
      <p>Essa página não existe.</p>
    </NotFoundContainer>
  </Layout>
);

export default NotFoundPage;
